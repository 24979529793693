export default {
  title: {
    en: 'Top curated fragrances',
  },
  text: {
    en: 'We offer the best designer and niche fragrances on the market selected by our team of experts.',
  },
  note: {
    en: 'And many more luxury brands to&#32;<br class="onlyMobile" />choose from.',
  },
}
